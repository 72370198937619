import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, CircularProgress, DialogContentText } from '@mui/material';
import { cleanState } from '../../redux/reducer/authoritiesRed';

export default function AuthoritiesHandler({action, button, values}) {
    const success = useSelector(state => state.authorities.successAction)
    const loading = useSelector(state => state.authorities.loading)
    const error = useSelector(state => state.authorities.error)
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [warning, setWarning] = React.useState(false)
    const initialState = {
        position:"",
        argentina:"",
        paraguay:""
    }
    const [state,setState] = React.useState(values || initialState)
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (e) =>{
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = () => {
        if(!state.position || !state.argentina || !state.argentina) return handleWarning()
        dispatch(action({data:state}))
        setOpen(false)
    };
    
    const handleWarning = () =>{
        setWarning(true)
        setTimeout(()=>setWarning(false),3000)
    }
    React.useEffect(()=>{
        if(success) {
            handleClose()
            Swal.fire({
                icon:'success',
                title:button==="add"?"Contenido agregado":'Contenido editado',
                timer:3000
        }).then(()=>{
            dispatch(cleanState())
            window.location.reload()
        })
    }
},[success])
React.useEffect(()=>{
    if(error){
            handleClose()
            Swal.fire({
                icon:'error',
                title:error,
                timer:3000
            }).then(()=>{
            dispatch(cleanState())
            // window.location.reload()
            })
        }
    },[])
  return (
    <>
        <span class="material-symbols-outlined" onClick={handleClickOpen}>
            {button}
        </span>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{button==="add"?"Agregar":"Editar"}</DialogTitle>
            <DialogContentText sx={{textAlign:"center"}}>La carga de autoridades es secuencial</DialogContentText>
            {warning?<Alert severity="warning">Completar todos los campos</Alert>: null}
            <DialogContent 
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: open ? "400px" : "0px"
                }} >
                {
                    loading ? (
                        <Box  sx={{ display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                    ) : (<>
                    
                    <TextField
                        autoFocus
                        name='position'
                        value={state.position}
                        onChange={handleChange}
                        margin="dense"
                        id="title"
                        label="Cargo"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        autoFocus
                        name='argentina'
                        value={state.argentina}
                        onChange={handleChange}
                        margin="dense"
                        id="argentina"
                        label="Argentina"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    
                    <TextField
                        autoFocus
                        name='paraguay'
                        value={state.paraguay}
                        onChange={handleChange}
                        margin="dense"
                        id="paraguay"
                        label="Paraguay"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    
                    
                </>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>{button==="add"?"Agregar":"Editar"}</Button>
            </DialogActions>
        </Dialog>
    </>
  );
}
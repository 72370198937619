import React, { useEffect } from "react";

const NavObserver = () => {

    return(
        <div id="navObserver" >
        </div>
    )
}

export default NavObserver
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {  deletenew } from "../../redux/reducer/newsReducer";
import AddNew from "./AddNew";

const New = (props) =>{
    var {img,title, text,date,containerStyle,id,linkPdf, html, isLogged} = props
    date = new Date(date).toLocaleDateString("es-AR")
    const dispatch = useDispatch()
    const addNewRef = React.useRef(null)
    const navigate = useNavigate()
    const goTo = (id) =>{
        navigate(`/noticia/${id}`)
    }
    const handleDelete = () => {
        console.log(id)
        const data = { id}
        Swal.fire({
            title:'Desea eliminar el artículo?',
            text:title,
            icon:'question',
            confirmButtonText:'Eliminar',
            confirmButtonColor:'#d33',
            showCancelButton:true
        }).then(value=>{
            value.isConfirmed && dispatch(deletenew(data))

        })
    }
    const handleClickOpen = () => {
        console.log("click")
        Swal.fire({
            title:'Desea editar el artículo?',
            text:title,
            icon:'question',
            confirmButtonText:'Editar',
            showCancelButton:true
        }).then(value=>{
            if (value.isConfirmed) {
                Swal.fire({
                    title:'Editar artículo',
                    text:'Esta funcionalidad se encuentra en desarrollo',
                    icon:'warning',
                    confirmButtonText:'Aceptar',
                    showCancelButton:false
                })
            }
        })

    };

    function truncateTitle(title, maxLength) {
        if (title.length > maxLength) {
          return title.substring(0, maxLength) + '...';
        }
        return title;
      }
    
    return(
        <div className={containerStyle} hover>
            <img src={img} alt="Ilustracion noticia"  onClick={()=>goTo(id)} className="new-img" />
            <div className="new-description-container" >
                <p className="new-date" >{date}</p>
                <p className="new-title" >{truncateTitle(title,70)}</p>
                {/* <p className="new-text" >{text}</p> */}
            </div>
            <div className="new-overlay" onClick={()=>goTo(id)} >
                <div className="new-overlay-text" >Ver más</div>
            </div>
            {isLogged?(
                <>
                    <span onClick={()=>handleClickOpen()} class="edit-new-button material-symbols-outlined">edit</span>
                    <span  onClick={()=>handleDelete()}  class="del-new-button material-symbols-outlined">delete</span>
                </>
            ):null}
        </div>
    )
}

export default New
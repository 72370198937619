import React from "react";
import { useNavigate } from "react-router-dom";
import './Hidrometeorologia.css'
import mapainteractivo from '../../assets/homepostsimgs/mapainteractivo.svg'
import r11 from '../../assets/homepostsimgs/r11.svg'
import Hidrometros from './Hidrometros'
const Hidrometeorología = ( ) => {
    const navigate = useNavigate()
    const navigateTo = (route)=>{
        navigate(`/${route}`)
    }
    
    return(
        <>
            <>
                <br/>
                
                <div className="hidro-line1-cont" >
                    <div onClick={()=>navigateTo('alturas')} className={'hidro-post-container'} >
                        <div className='carta' >
                            <div className='cara' >
                                <img className='article-img2' src='/images/Boton_Alturas-123.svg' alt="related img"/>
                                <p className='article-title'>Alturas</p>
                            </div>
                            <div className='cara detras' >                    
                                <div className='article-title-back'>Alturas</div>

                            </div>

                        </div>
                    </div>
                    <div className={'hidro-post-container'} >
                        <a href="https://mapa.comip.ar/map" target='blank' >
                            <div className='carta' >
                                <div className='cara' >
                                    <img className='article-img' src={mapainteractivo} alt="related img"/>
                                    <p className='article-title'>Mapa Interactivo</p>
                                </div>
                                <div className='cara detras' >                    
                                    <div className='article-title-back'>Mapa Interactivo</div>
                                                        
                                </div>
                            </div>
                        </a>
                    </div>
                
                    {/* <div onClick={()=>navigateTo('agua','r11')} className={'hidro-post-container'} > */}
                    <div  className={'hidro-post-container'} >
                        <a href="https://mapa.comip.ar/map?hist=1" target="blank" rel="noreferrer" >
                            <div className='carta' >
                                <div className='cara' >
                                    <img className='article-img' src={r11} alt="related img"/>
                                    <p className='article-title'>Datos Históricos</p>
                                </div>
                                <div className='cara detras' >                    
                                    <div className='article-title-back'>Datos Históricos</div>
                                                        
                                </div>

                            </div>
                        </a>
                    </div> 
                    
                </div>
                <div className="hidro-line2-cont" >
                    
                </div>
                {/* <Hidrometros/> */}
            </>
        </>
    )
}

export default Hidrometeorología
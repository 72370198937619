import React from "react";
import Footer from "../Footer/Footer";
import './Convenios.css'
import HeadingPic from '../../assets/images/inst/Encabezado Convenios.jpg'
import ScrollToTop from "../ScrollToTop";
import NavObserver from "../NavObserver";
import { getConvenios } from "../../services/convenios";
import { useSelector } from "react-redux";
import AddItem from "../AddItem";
import {add, edit} from "../../redux/reducer/itemsReducer"
import RmItem from "./RmItem";
const Convenios =() =>{
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const [convenios,setConvenios] = React.useState([])
    const [loading,setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    
    React.useEffect(()=>{
        async function fetchData() {
            setLoading(true)
            try{
                const fetchConvenios = await getConvenios()
                setConvenios(fetchConvenios)
            }catch(e){
                setError(e)
            }
            setLoading(false)
        }
        fetchData();
    },[])
    const falseState=convenios.map(item=>false)
    const [showQuestions, setShowQuestions] = React.useState(falseState)
    const toggleShow = (i) =>{
        const currentState=showQuestions[i]
        let items = [...falseState]
        items[i] = !currentState
        setShowQuestions(items)
    }
    
    return(
        <>
            <div id="section-container" >
                <NavObserver/>
                <div className="institutional-pic" >
                    <img className="heading-pic" src={HeadingPic} alt="encabezado"/>
                </div>
                <div  className="authorities-container" >
                    <div className="authorities-title" >Convenios de Cooperación</div>
                    <div className="separator-auth-line" ></div>
                    <br/>
                    <div className="convenios-container" >
                        {isLoggedIn && <div className="agregar-convenio" >
                            <AddItem action={add} button="add" route="convenios" />
                            
                            </div>}
                        {
                            convenios.map((convenio,index)=>{
                                return (
                                    <div className="convenio-container" >
                                        <div onClick={()=>toggleShow(index)} className={showQuestions[index]?"convenio-title-active":"convenio-title"} >
                                            
                                            {convenio.title}
                                        </div>
                                        <div  className={showQuestions[index]?"convenio-description-show":"convenio-description"} dangerouslySetInnerHTML={{__html: convenio.description}} >
                                            
                                        </div>
                                            
                                        <div className="conv-btn-container" >
                                            <a className={showQuestions[index]?  "convenio-download-btn":"convenio-description"}href={convenio.url} target='_blank' rel="noreferrer nofollow" >
                                            Ver convenio
                                            </a>
                                            {isLoggedIn && showQuestions[index] && <div className="edit-conv" >
                                                <AddItem action={edit} button="edit" values={convenio} route="convenios" />
                                                <RmItem values={convenio} route="convenios" />
                                            </div>}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                <Footer/>
                </div>
            </div>
                <ScrollToTop div='section-container' />
        </>
    )
}

export default Convenios
export const comipInfo = {
    title:"¿Qué es la COMIP?",
    text:[
        "La República del Paraguay y la República Argentina suscribieron un Convenio por el que se crea la <strong> Comisión Mixta argentino – paraguaya del Río Paraná </strong>, COMIP. Este Convenio fue aprobado por Ley paraguaya Nº 270 del 30 de julio de 1.971 y por Ley argentina Nº 19.307 de fecha 11 de Octubre de 1971. El canje de los instrumentos de ratificación tuvo lugar el 29 de diciembre de 1971, fecha en la que el Convenio entró en vigor.",
        "La COMIP tiene por objeto el “estudio y evaluación de las posibilidades técnicas y económicas del aprovechamiento de los recursos del río Paraná en el tramo limítrofe entre los dos países, desde su confluencia con el río Paraguay hasta la desembocadura del Iguazú” (art. 1). Dentro de ese espacio, el artículo 2 del convenio excluye de la competencia de la COMIP las facultades otorgadas a la entonces Comisión Técnica Mixta Paraguayo Argentina Yacyretá-Apipé, a quien sucedió en 1973 la Entidad Binacional Yacyretá. El artículo 4 del Reglamento técnico-administrativo de la COMIP precisa que ésta es una organización internacional que goza de la capacidad jurídica necesaria para adquirir derechos, contraer obligaciones y realizar todos los actos necesarios para la obtención de sus fines.",
        "Si se ubica a la COMIP dentro de los diferentes tipos de entidades descriptos en el parágrafo anterior, se la puede definir como una organización internacional, con competencia en un tramo fluvial y con capacidad para realizar estudios y evaluaciones sobre la posibilidad de aprovechamiento de los recursos del río Paraná. El Convenio de 1971 se refiere a los “recursos del río Paraná”. Esta terminología comprende estudios y evaluaciones en diversas áreas como producción de electricidad, navegación, pesca, uso industrial y agrícola de las aguas, uso recreativo y todo lo referido a la protección del río.",
        "Con acuerdos posteriores celebrados entre el Paraguay y la Argentina, la COMIP ha ido aumentando su competencia. Así, el convenio tripartito del 19 de octubre de 1979 le atribuyó responsabilidades para intercambiar datos hidrológicos con Itaipú binacional y para verificar si se respetan los parámetros estipulados en ese convenio sobre velocidad de las aguas y variaciones horarias y diarias del nivel.  Además, el 26 de abril de 1989 se confirió a la COMIP competencia en cuanto a control de la calidad del agua y del recurso íctico.  Esto fue precisado por un acuerdo posterior el 15 de septiembre de 1989.",
        "El 26 de marzo de 1992 los Cancilleres de los dos países se reunieron en Buenos Aires y acordaron “que una única Comisión se ocupe de todos los aspectos vinculados con el tramo del Río Paraná que es contiguo” entre la Argentina y el Paraguay.  En este sentido, acordaron “otorgar a la COMIP competencia semejante a las comisiones fluviales binacionales existentes en el ámbito de la Cuenca del Plata”.  En el acuerdo que celebraron ese día enunciaron las facultades que tiene la COMIP en virtud de convenios anteriores y agregaron “navegación y control del medio ambiente”.",
        "En 1992 se encomienda al organismo la elaboración de un proyecto de estatuto del río, que comprenda también el nuevo reglamento de funcionamiento de la Comisión.  El estatuto del río consiste fundamentalmente en la regulación de los distintos usos de las aguas.",
        "A partir de la firma del “Convenio sobre conservación y desarrollo de los recursos ícticos en los tramos limítrofes de los ríos Paraná y Paraguay” el 25 de octubre de 1996, la COMIP es Secretaría Permanente del Comité Coordinador."
    ]
}
export const homeposts = [
    {
        title:"Datos Hístoricos",
        img:"r11.svg",
        text:"Alturas históricas de las estaciones",
        imgSide:"left",
        navigate:true,
        to:'/datos-historicos',
        href:"https://mapa.comip.ar/map?hist=1"
    },
    {
        title:"Mapa Interactivo",
        img:"mapainteractivo.svg",
        text:"Mapa interactivo de la cuenca del Tramo COMIP",
        navigate:false,
        href:'https://mapa.comip.ar/map'
    },
    {
        title:"Determinantes",
        img:"determinantes.svg",
        text:"Profundidades diarias de los principales pasos críticos del Tramo COMIP",
        navigate:true,
        to:'/nav/determinantes'
    }
]
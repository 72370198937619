import { configureStore} from '@reduxjs/toolkit'
import authSlice from "../reducer/authReducer.js";
import newsSlice from "../reducer/newsReducer";
import authoritiesSlice from "../reducer/authoritiesRed"
import itemsSlice from "../reducer/itemsReducer"
import encabezados from '../reducer/encabezados.js';
const store =configureStore({
    reducer:{
        auth:authSlice,
        news: newsSlice,
        authorities:authoritiesSlice,
        items:itemsSlice,
        enc:encabezados
    }
});

export default store;
import React, { useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditor.css'

const TextEditor = ({setBody}) => {
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
      );
      useEffect(() => {
        let html = convertToHTML({
          entityToHTML: (entity, originalText) => {
            if (entity.type === 'IMAGE') {          
              return `<img src="${entity.data.src}" />`;
            }
            if (entity.type === 'LINK') {
              // return `<a href="${entity.data.url}">${originalText}</a>`;
              const target = entity.data.target || '_blank';
              return `<a href="${entity.data.url}" target="${target}">${originalText}</a>`;
            }
            return originalText;
          },
        })(editorState.getCurrentContent());
        setBody(html);
      }, [editorState]);
    return (
    <>
        <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            editorClassName="editor-class"
        />
    </>
  )
}

export default TextEditor
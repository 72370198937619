import React from "react";
import Slider from "../Slider";
import Result from "./Result";
import './ResultsList.css'
const ResultsList = ({list,addEmpty}) =>{
    return(
        <>
            {/* <div className="resultlist-container" >
            {
                list.map(item=><Result details={item} />)
            }
            {
                addEmpty && <div className="result-container empty-item" ></div>
            }
            </div> */}
            <Slider slides={list} />
        </>
    )
}

export default ResultsList
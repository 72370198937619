import React from "react";
import { headers } from "../../assets/data/navegacion/determinantes/headers";
import './TableDet.css'
import moment from 'moment'
const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY')
}
const TableDet = ({Parana,Paraguay}) =>{
    const [data, setData] = React.useState(Parana)
    const [date, setDate] = React.useState(formatDate(Parana[0].fecha_altura.split(" ")[0]))
    const handleData = (value) =>{
        setData(value)
        setDate(formatDate(value[0].fecha_altura.split(" ")[0]))
    }
    function titleCase(str) {
        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
          str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
        }
        return str.join(' ');
      }
      titleCase("I'm a little tea pot")
    const titles = headers.map(item=>titleCase(item.title)) 
    return(
    <>
        <br/>
        <br/>
        <div className="determinanes-buttons" >
            <div onClick={()=>handleData(Parana)} className={data===Parana?"det-btn-active":"det-btn"} >Río Paraná</div>
            <div onClick={()=>handleData(Paraguay)} className={data===Paraguay?"det-btn-active":"det-btn"} >Río Paraguay</div>
        </div>
        <br/>
        <p>Fecha de actualización: {date}</p>
        <br/>
        
        {
            <table className="det-table" >
                <tbody>
                    <tr>
                    {
                        titles.map((item,i)=>{return <th key={item+i}>{item}</th> })
                    }
                    </tr>
                    {
                        data.map((line,i)=>{
                            return(
                                <tr  key={i}>
                                    {
                                        headers.map((item,j)=>{return <th key={item.key+j}>{line[item.key]}</th>})
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        }
    </>
    )
}

export default TableDet
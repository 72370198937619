import React from "react";
import './AuthList.css'
import AuthoritiesHandler from "./AuthoritiesHandler";
import { edit } from '../../redux/reducer/authoritiesRed';
import RmAuthority from "./RmAuthority";

const AuthList = ({authorities, isLoggedIn}) =>{
    return(
        <div className="auth-list-container" >
            {
                authorities?.length>0 && authorities.map(auth=>{
                    return (
                        <div className="authority-item" >
                            
                                <div className="auth-position" >
                                {auth.position}
                                {
                                isLoggedIn && 
                                <div className="authorities-buttons-cont" >
                                    <AuthoritiesHandler action={edit} button="edit" values={auth} />
                                    &nbsp;
                                    <RmAuthority values={auth} />

                                </div>
                                }
                                </div>
                                <div className="authority-countries" >
                                    <div className="authority-country" >
                                        <div className="country-person" >{auth.argentina || "-"}</div>
                                        <div className="country-name" >Argentina</div>
                                    </div>
                                    <div className="authority-country" >
                                        <div className="country-person" >{auth.paraguay || "-"}</div>
                                        <div className="country-name" >Paraguay</div>
                                    </div>
                                </div>

                            
                            
                        </div>
                    )
                })
            }
        </div>
    )
}

export default AuthList
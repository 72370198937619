import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axios from '../../api/index'
  
  const initialState = {
    error:null,
    successAction:false,
    successDeleted:false,
    loading:false
}

export const add = createAsyncThunk(
    'add',
    async({data},{rejectWithValue})=>{
        try{
            const added = await axios.requestData('post','/jobs',data)
            console.log("added")
            if(added.data.status === 'ok' ) return added.data
            else return rejectWithValue(false)
        }catch(e){
            console.log(e)
            return rejectWithValue({message:e.response.data.error||e.message})
        }
    }
)
export const remove = createAsyncThunk(
    'remove',
    async(data,{rejectWithValue})=>{
        try{
            const added = await axios.requestData('post','/jobs/remove',data)
            if(added.data.status === 'ok' ) return added.data
            else return rejectWithValue(added.data.error || false)
        }catch(e){
            console.log(e)
            return rejectWithValue({message:e.response.data.error||e.message})
        }
    }
)
export const edit = createAsyncThunk(
    'edit',
    async(data,{rejectWithValue})=>{
        try{
            const edited = await axios.requestData('post','/jobs/edit',data)
            if(edited.data.status === 'ok' ) return edited.data
            else return rejectWithValue(edited.data.error || false)
        }catch(e){
            console.log(e)
            return rejectWithValue({message:e.response.data.error||e.message})
        }
    }
)

const authoritiesSlice = createSlice({
    name:'authoritiesSlice',
    initialState,
    reducers:{
        cleanSuccess: (state)=>{
            state.successAction = false
        },
        cleanSuccessDeleted: (state)=>{
            state.successDeleted = false
        },
        cleanError: (state)=>{
            state.error = null
        },
        cleanState:(state) => state=initialState
    },
    extraReducers:{
        [add.fulfilled]: (state, {payload}) => {
            state.loading = false;
            state.successAction=true
        },
        [add.pending]: (state, {payload}) => {
            state.loading = true;
        },
        [add.rejected]: (state, {payload}) => {
            state.loading = false;;
            state.error = payload
        },
        [edit.fulfilled]: (state, {payload}) => {
            state.loading = false;
            state.successAction=true
        },
        [edit.pending]: (state, {payload}) => {
            state.loading = true;
        },
        [edit.rejected]: (state, {payload}) => {
            state.loading = false;;
            state.error = payload
        },
        [remove.fulfilled]: (state, {payload}) => {
            state.loading = false;
            state.successDeleted=true
        },
        [remove.pending]: (state, {payload}) => {
            state.loading = true;
        },
        [remove.rejected]: (state, {payload}) => {
            state.loading = false;;
            state.error = payload
        },
    }
})

export const {cleanError, cleanState, cleanSuccess, cleanSuccessDeleted} = authoritiesSlice.actions
export default authoritiesSlice.reducer
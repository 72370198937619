import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import './Slider.css'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/zoom";

import { Autoplay, Pagination, Navigation, Zoom } from "swiper";

const Slider = ({slides}) =>{
    return <Swiper
            spaceBetween={1}
            centeredSlides={true}
            autoplay={{
            delay: 10000,
            disableOnInteraction: false,
            }}
            zoom={true}
            navigation={true}
            pagination={{
            clickable: true,
            }}
            style={{
                "--swiper-navigation-size": "60px",
            }}
            modules={[Autoplay, Pagination, Navigation, Zoom]}
            className="mySwiper"
                >
                {
                    slides.map((s,i)=>{
                        return <SwiperSlide>
                        <div className='swiper-zoom-container' >
                            <img className='' src={s} alt='slide' />

                        </div>
                            </SwiperSlide>
                    })
                }
                
            </Swiper>
}

export default Slider
export const headers =[
        {
        title:"DESDE KM",
        key:"desde_km"

    },
        {
        title:"HASTA KM",
        key:"hasta_km"

    },
        {
        title:"KM CRÍTICO",
        key:"km_critico"

    },
        {
        title:"DENOMINACIÓN DEL PASO",
        key:"den_paso"

    },
        {
        title:"ANCHO CANAL (M)",
        key:"ancho_canal"

    },
        {
        title:"PROF. AL CERO (M)",
        key:"prof_cero"

    },
        {
        title:"ALTURA HIDRÓMETRO (M)",
        key:"altura"

    },
        {
        title:"PROF. EFECTIVA",
        key:"prof_efect"

    },
        {
        title:"SE REDUCE CON HIDRÓMETRO DE PUERTO",
        key:"puerto"

    },
        {
        title:"FECHA SONDAJE",
        key:"fecha_altura"

    },
        {
        title:"DETERMINANTE",
        key:"determinante"

    },
]
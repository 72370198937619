import React from "react";
import './RioParana.css'
import trifinio from '../../assets/images/rio/2_Rio Parana/2_Trifinio_Mesa de trabajo 1.jpg'

import ParanaStretches from "./ParanaStretches";
import Regimen from "./Regimen";
const RioParana =()=>{
    const text=[
        "El Paraná es el río más influyente del sistema de la Cuenca del Plata, por su caudal, por la extensión de su área tributaria y por la longitud de su curso, entre otras características. El río nace entre los estados brasileños de São Paulo, Minas Gerais y Mato Grosso del Sur, de la confluencia del río Grande y el río Paranaíba. Corre hacia el sudoeste, para delimitar el estado de Mato Grosso del Sur con los de São Paulo y Paraná hasta la ciudad de Salto del Guairá, desde donde demarca la frontera entre Brasil y Paraguay hasta la Triple frontera entre Paraguay, Argentina y Brasil.","Desde su origen en la confluencia con los ríos Paranaíba y Grande (Brasil) hasta su desembocadura en el Río de la Plata, tiene 2.570 km. Sumada la extensión de su afluente principal, el Paranaíba, alcanza los 3.740 km.",

    ]
    return(
        < >
            {
                text.map(p=>{
                    return <>
                        <p dangerouslySetInnerHTML={{__html:p}}/>
                    </>
                })
            }
            <div className="section-subtitle" >Trifinio</div>
            <div className="separator-auth-line-section" ></div>
            <br/>
            <br/>
            <img className="trifinio-imagen" src={trifinio} alt="imagen trifinio" loading="lazy" />
            {/* <br/>
            <br/> */}
            <ParanaStretches/>
            <br/>
            <br/>
            <br/>
            <div className="stretches-place" ></div>

            
            <Regimen/>
            
        </>
    )
}

export default RioParana
import React from 'react'
import './Encarnacion.css'
import { getActasEnc } from "../../services/actasenc";
import { useSelector } from "react-redux";
import AddItem from "../AddItem";
import {add, edit} from "../../redux/reducer/itemsReducer"
import AddActa from '../AddActa';
import RmItem from '../Institutional/RmItem';
const Encarnacion = () =>{
    
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const [actasenc,setActasEnc] = React.useState([])
    const [loading,setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    React.useEffect(()=>{
        async function fetchData() {
            setLoading(true)
            try{
                const fetchActasEnc = await getActasEnc()
                setActasEnc(fetchActasEnc)
            }catch(e){
                setError(e)
            }
            setLoading(false)
        }
        fetchData();
    },[])

    
    return(
        <>
            <nr/>
            {isLoggedIn && <div className="agregar-convenio" >
                <AddActa action={add} button="add" route="actasenc" />
                
                </div>}
            <div className='encarnacion-cards-container' >
                {
                    actasenc.map(card=>{
                        return(
                            <div className={'enc-card-container'} >
                                <div className='enc-carta' >
                                    <div className='enc-cara' >
                                        <div>{card.title}</div>
                                        <div>{card.date}</div>
                                    </div>
                                    <div className='enc-cara cc-detras' >
                                        <a className='enccard-title-back' href={card.url} target='_blank' rel="noreferrer nofollow" >
                                        Descargar PDF
                                        </a>
                                        {isLoggedIn && <div className="edit-conv" >
                                                <AddActa action={edit} button="edit" values={card} route="actasenc" />
                                                <RmItem values={card} route="actasenc" />
                                            </div>}
                                    </div>

                                </div>
                            </div>
                        )
                    })

                }

            </div>
        </>
    )
}

export default Encarnacion
import React from "react";
import { tramoComip } from "../../assets/data/rio/tramocomip";
import CountryMaps from "./CountryMaps";

const TramoComip = () =>{
    const paraguay=[
        {headline:"",img:"",references:[]}
    ]
    return(
        <>
            <p className="tramo-comip-text" >
            El área de incumbencia de la COMIP comprende el río Paraná en el tramo limítrofe entre Argentina y Paraguay, desde la desembocadura del río Iguazú hasta su confluencia con el río Paraguay. Se excluye de su competencia las facultades otorgadas a la entonces Comisión Mixta Paraguayo-Argentina de Yacyretá-Apipé (1958), sucedido por la Entidad Binacional Yacyretá (Tratado de Yacyretá – 1973).
            </p>
            <br/>
            <br/>
            <div className="subsection-title" >
            Mapas temáticos: Argentina y Paraguay
            </div>
            {/* <br/> */}
            {/* <div className="separator-maps-line" ></div> */}
            <div>Para su confección se consultaron fuentes oficiales de cada país.</div>
            <br/>
            <br/>
            <div className="subsection-title" >PARAGUAY</div>
            <br/>
            <CountryMaps list={tramoComip.paraguay} />
            <br/>
            <br/>
            <div className="subsection-title" >ARGENTINA</div>
            <br/>
            <CountryMaps list={tramoComip.argentina} />
        </>
    )
}

export default TramoComip
import React, { useState } from "react";
import Swal from "sweetalert2";
import { sendNotification } from "../services/notification";

const initialState={
    name: '',
    email: '',
    subject: '',
    message:'',
}
const ContactForm = () => {
    const [state, setState] = useState(initialState)
    const [loading, setLoading] = useState(false)
    const handleChange = (e)=>{
        setState({
            ...state,
            [e.target.name] : e.target.value
        })
    }
    async function handleSubmit(event) {
        event.preventDefault();
        if(!state.name || !state.email || !state.subject || !state.message) return Swal.fire({
            text: "Completar todos los campos",
            toast: true,
        })
        else {
            setLoading(true)
            const sent = await sendNotification(state)
            setLoading(false)
            if(sent.status==='ok') {
                Swal.fire({
                    icon:'success',
                    title:'El mensaje se envió con éxito',
                    timer:3000
                }).then((value)=>{
                    value && setState(initialState)
            })
            } else {
                Swal.fire({
                    icon:'error',
                    title:'Error',
                    text: sent.error || 'Algo salió mal',
                    timer:3000
                })
            }
        }
        
    }
    return(
        <>
            
        <div className="contact-form-container">
            <br/>
            <br/>
            <form className="form-items" onSubmit={(e)=> {handleSubmit(e)} } >
                <div className="form-item" >
                    <label>Tu nombre (requerido)</label>
                    <input className="input-container" type="text" placeholder="Escribir..." name = "name" value = {state.name} onChange={(e)=> handleChange(e) } />
                </div>
                <div className="form-item" >
                    <label>Tu correo electrónico (requerido)</label>
                    <input className="input-container" type="text" placeholder="Escribir..." name = "email" value = {state.email} onChange={(e)=> handleChange(e) } />
                </div>
                <div className="form-item" >
                    <label>Asunto (requerido)</label>
                    <input className="input-container" type="text" placeholder="Escribir..." name = "subject" value = {state.subject} onChange={(e)=> handleChange(e) } />
                </div>
                <div className="form-item" >
                    <label>Tu mensaje (requerido)</label>
                    <textarea 
                        className="input-container" 
                        type="text" 
                        placeholder="Escribir..." 
                        rows={4}
                        name = "message" 
                        value = {state.message} 
                        onChange={(e)=> handleChange(e) } 
                        // onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }} 
                        />
                </div>
                <button className={loading?"contact-submit disabled":"contact-submit"} type="submit" disabled={loading}>Enviar</button>
                <br/>
                <br/>
                <br/>
                
            </form>
        </div>
        </>
    )
}

export default ContactForm
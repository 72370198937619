import React from "react";
import { useSelector } from 'react-redux';
import Footer from "../Footer/Footer";
import AuthList from "./AuhtsList";
import './Authorities.css'
import HeadingPic from '../../assets/images/Encabezados-Autoridades.png'
import ScrollToTop from "../ScrollToTop";
import NavObserver from "../NavObserver";
import { getAuthorities } from "../../services/authorities";
import { add } from '../../redux/reducer/authoritiesRed';
import AuthoritiesHandler from "./AuthoritiesHandler";
const Authorities = () =>{
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const [authorities,setAuthorities] = React.useState([])
    const [loading,setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)

    React.useEffect(()=>{
        async function fetchData() {
            setLoading(true)
            try{
                const fetchAuthorities = await getAuthorities()
                setAuthorities(fetchAuthorities)
            }catch(e){
                setError(e)
            }
            setLoading(false)
        }
        fetchData();
    },[])
    // const HeadingPic = "https://res.cloudinary.com/diqcwn9e9/image/upload/v1666223907/comip/institucional/Cafiero_y_Acevedo_agenda_bilateral_y_regional_de_Argentina_y_..._imb8z7.jpg"
    return(
        <>
        <div id="section-container" >
            <NavObserver/>
            <div className="institutional-pic" >
                <img className="heading-pic" src={HeadingPic} alt="encabezado"/>
            </div>
            <div  className="authorities-container" >
                <div className="authorities-title" >Autoridades</div>
                <div className="separator-auth-line" ></div>
                    {isLoggedIn && 
                                <div className="agregar-autoridad" >
                                    <AuthoritiesHandler action={add} button="add"  />
                                </div>
                    }
                <div className="lists-auth-container" >
                    <AuthList authorities={authorities} isLoggedIn={isLoggedIn}/>
                </div>
            <Footer/>
            </div>
        </div>
            <ScrollToTop div='section-container' />
        </>
    )
}

export default Authorities
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axios from '../../api/index'
  
  const initialState = {
    list:null,
    successAction:false,
    successDeleted:false,
    loading:false
}


export const getAll = createAsyncThunk(
    'getAll',
    async(_,{rejectWithValue})=>{
        try{
            const edited = await axios.request('get',`/encabezados`)
            if(edited.data.status === 'ok' ) return edited.data.data
            else return rejectWithValue(edited.data.error || false)
        }catch(e){
            console.log(e)
            return rejectWithValue({message:e.response.data.error||e.message})
        }
    }
)
export const edit = createAsyncThunk(
    'edit',
    async({data,route},{rejectWithValue})=>{
        console.log(data)
        try{
            const edited = await axios.requestData('post',`/${route}/edit`,data)
            if(edited.data.status === 'ok' ) return edited.data
            else return rejectWithValue(edited.data.error || false)
        }catch(e){
            console.log(e)
            return rejectWithValue({message:e.response.data.error||e.message})
        }
    }
)

const encabezadosSlice = createSlice({
    name:'encabezadosSlice',
    initialState,
    reducers:{
        cleanSuccess: (state)=>{
            state.successAction = false
        },
        cleanError: (state)=>{
            state.error = null
        },
        cleanState:(state) => state=initialState
    },
    extraReducers:{
        [getAll.fulfilled]: (state, {payload}) => {
          state.list=payload;
            state.loading = false;
            state.successAction=true
        },
        [getAll.pending]: (state, {payload}) => {
            state.loading = true;
        },
        [getAll.rejected]: (state, {payload}) => {
            state.loading = false;;
            state.error = payload
        },
        [edit.fulfilled]: (state, {payload}) => {
            state.loading = false;
            state.successAction=true
        },
        [edit.pending]: (state, {payload}) => {
            state.loading = true;
        },
        [edit.rejected]: (state, {payload}) => {
            state.loading = false;;
            state.error = payload
        },
    }
})

export const {cleanError, cleanState, cleanSuccess, cleanSuccessDeleted} = encabezadosSlice.actions
export default encabezadosSlice.reducer
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { cleanSuccessDeleted, remove } from "../../redux/reducer/itemsReducer";

export default function RmItem({values,route}){
    const dispatch = useDispatch()
    const successDeleted = useSelector(state => state.items.successDeleted)
    const handleDelete = () => {
        const data = { id:values.id}
        Swal.fire({
            title:route==="convenios"?'Desea eliminar este convenio?':"Desea eliminar esta acta",
            text:values.position,
            icon:'question',
            confirmButtonText:'Eliminar',
            showCancelButton:true
        }).then(value=>{
            value.isConfirmed && dispatch(remove({data,route}))
        })
    }
    React.useEffect(()=>{
        if(successDeleted){
            Swal.fire({
                title:'Convenio eliminado con éxito',
                icon:'success',
                confirmButtonText:'Ok'
            }).then(value=>{
                if(value){
                    dispatch(cleanSuccessDeleted())
                    window.location.reload()
                }
            })
        }
    },[successDeleted])
    
    return (
        <>
            <span onClick={()=>handleDelete()} class=" material-symbols-outlined">
                delete
            </span>
        </>
    )
}
const urlBase = "https://files.comip.org.ar/fauna-convenios/"
export const cards = [
    {
        date:"25/10/2022",
        description:"Resolución N° 385/2022, Resolución del Ministerio de Ecología y Recursos Naturales.",
        zone:"Misiones",
        img:"/images/Fauna/1_Convenio/Imagen 1.jpg",
        pdf:urlBase + "Resolucion-N-07-2021-Veda-Extraordinaria-Misiones.pdf"
    },
    {
        date:"3/11/2022",
        description:"Disp. Nro. 605/2022, Dirección de Recursos Naturales de Corrientes,Provincia de Corrientes.",
        zone:"Corrientes",
        img:"/images/Fauna/1_Convenio/Imagen 2.jpg",
        pdf:urlBase + "PESCA-DEPORTIVA-DISPOSICION-No123.pdf"
    },
    {
        date:"2/12/2020",
        description:"Res. Nro. 1483, Secretaria de Desarrollo, Territorial y Ambiente, Prov. del Chaco.",
        zone:"Chaco",
        img:"/images/Fauna/1_Convenio/Imagen 3.jpg",
        pdf:urlBase + "Resol.-N-1483-20-Determinese-que-la-pesca-deportiva-queda-autorizado-VIE-SAB-DOM-y-feriados-4-personas-por-embarcacion.pdf"
    },
    {
        date:"13/10/2022",
        description:"Memorándum Nº 713/2022,  Ministerio de Salud Pública y Bienestar, República del Paraguay.",
        zone:"Paraguay",
        img:"/images/Fauna/1_Convenio/Imagen 4.jpg",
        pdf:urlBase + "RESOLUCION-N°-713_22.pdf"
    },
    {
        date:"31/10/2022",
        description:"Disp. Nro. 102/2022, Dirección de Recursos Naturales de Formosa, Provincia de Formosa.",
        zone:"Formosa",
        img:"/images/Fauna/1_Convenio/Imagen 5.jpg",
        pdf:urlBase + "Dispo-Veda-pesca-102-22-1.pdf"
    },
]
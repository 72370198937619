import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, CircularProgress, DialogContentText } from '@mui/material';
import { cleanState } from '../redux/reducer/itemsReducer';

export default function AddActa({action, button, values, route}) {
    const success = useSelector(state => state.authorities.successAction)
    const loading = useSelector(state => state.authorities.loading)
    const error = useSelector(state => state.authorities.error)
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [warning, setWarning] = React.useState(false)
    
    const [state,setState] = React.useState( values || {
        url:"",
        title:"",
        date:""
    })
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (e) =>{
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = () => {
        if(!state.url || !state.title || !state.date) return handleWarning()
        dispatch(action({data:state, route}))
        setOpen(false)
    };
    
    const handleWarning = () =>{
        setWarning(true)
        setTimeout(()=>setWarning(false),3000)
    }
    React.useEffect(()=>{
        if(success) {
            handleClose()
            Swal.fire({
                icon:'success',
                title:button ==="add"?"Acta agregado":"Acta editada" ,
                timer:3000
        }).then(()=>{
            dispatch(cleanState())
            window.location.reload()
        })
    }
},[success])
React.useEffect(()=>{
    if(error){
            handleClose()
            Swal.fire({
                icon:'error',
                title:error,
                timer:3000
            }).then(()=>{
            dispatch(cleanState())
            // window.location.reload()
            })
        }
    },[])
  return (
    <>
        <span class="material-symbols-outlined" onClick={handleClickOpen}>
            {button==="add"?"add": "edit"}
        </span>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{button==="add"?"Agregar Acta":"Editar Acta"}</DialogTitle>
            <DialogContentText sx={{textAlign:"center"}}>La carga es secuencial</DialogContentText>
            {warning?<Alert severity="warning">Completar todos los campos</Alert>: null}
            <DialogContent 
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: open ? "400px" : "0px"
                }} >
                {
                    loading ? (
                        <Box  sx={{ display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                    ) : (<>
                    
                    <TextField
                        autoFocus
                        name='url'
                        value={state.url}
                        onChange={handleChange}
                        margin="dense"
                        id="url"
                        label="Url"
                        type="text"
                        fullWidth
                        variant="standard"
                    /> 
                    <TextField
                        autoFocus
                        name='title'
                        value={state.title}
                        onChange={handleChange}
                        margin="dense"
                        id="title"
                        label="Titulo"
                        type="text"
                        fullWidth
                        variant="standard"
                    /> 
                    <TextField
                        autoFocus
                        name='date'
                        value={state.date}
                        onChange={handleChange}
                        margin="dense"
                        id="date"
                        label="Fecha"
                        type="text"
                        fullWidth
                        variant="standard"
                    /> 
                </>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleSubmit}>{button==="add"?"Agregar":"Editar"}</Button>
            </DialogActions>
        </Dialog>
    </>
  );
}